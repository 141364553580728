<template>
  <v-container>
    <v-form ref="form">
      <v-row>
        <v-col text-center>
          <div class="d-flex justify-center">
            <span class="h3 semiBold">Registrar nuevo usuario</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card class="bordered py-6" elevation="0" outlined height="520">
            <div class="card-content">
              <v-card-text>
                <v-row class="px-8">
                  <v-col cols="12" md="6" class="pr-8">
                    <LabelComponent text="Rut*" />
                    <Input
                      size="long"
                      color="#0077c8"
                      :model="rut"
                      label="RUT"
                      :hide="false"
                      readonly="false"
                      :rules="[rules.required]"
                      @input="rut = $event"
                      max_length="12"
                      :rut="true"
                      name="RegisterRut"
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="pl-8">
                    <LabelComponent text="Nombre*" />
                    <TextField :fields="Nombre" name="RegisterName" />
                  </v-col>
                </v-row>
                <v-row class="px-8">
                  <v-col cols="12" md="6" class="pr-8">
                    <LabelComponent
                      text="Primer apellido*"
                      name="RegisterLastname"
                    />
                    <TextField :fields="Apellido" />
                  </v-col>
                  <v-col cols="12" md="6" class="pl-8">
                    <LabelComponent
                      text="Segundo apellido*"
                      name="RegisterSecondLastname"
                    />
                    <TextField :fields="SegundoApelido" />
                  </v-col>
                </v-row>
                <v-row class="px-8">
                  <v-col cols="12" md="6" class="pr-8">
                    <LabelComponent text="Correo*" name="RegisterEmail" />
                    <TextField :fields="Correo" />
                  </v-col>
                  <v-col cols="12" md="6" class="pl-8">
                    <LabelComponent text="Teléfono*" name="RegisterPhone" />
                    <TextField :fields="phone" />
                  </v-col>
                </v-row>
                <v-row class="px-8">
                  <v-col cols="12" md="6" class="pr-8">
                    <LabelComponent text="Canal*" name="RegisterChannel" />
                    <Select
                      :fields="Text_Canal"
                      @change="getSucursal()"
                      color="#0077c8"
                      :rules="[rules.required_canal]"
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="pl-8">
                    <LabelComponent text="Sucursal*" name="RegisterStore" />
                    <Select
                      :fields="Text_Sucursal"
                      color="#0077c8"
                      :rules="[rules.required_sucursal]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="d-flex justify-end mt-4 mr-8">
                      <v-btn
                        rounded
                        color="primary"
                        outlined
                        width="140"
                        class="text-none"
                        v-on:click.native="Return"
                      >
                        Volver
                      </v-btn>
                      <v-btn
                        :loading="loadingstatus"
                        color="primary"
                        rounded
                        width="140"
                        class="text-none ml-4"
                        v-on:click.native="CreateUser()"
                        >Registrar</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

    <Modal
      :close="closeDialog"
      :open="usermodal"
      :name="Nombre.text"
      :email="Correo.text"
      :rut="rut"
      SuccessPath="Register"
    />
    <snackbar
      :snackbar="snackbarActivated"
      :msg="errorMsgUser"
      @closeError="closeSnack"
    />
  </v-container>
</template>

<script>
import TextField from "@/components/Inputs/TextField";
import Select from "@/components/Inputs/SelectLabel";
import Input from "@/components/Inputs/Input.vue";
import Modal from "@/components/Modal/Modal-New-User.vue";
import { Register } from "@/Mixins/RegisterMixin.js";
import snackbar from "@/components/Snackbar/Snack-error";
import LabelComponent from "@/components/Inputs/Label.vue";

export default {
  mixins: [Register],
  components: {
    TextField,
    Select,
    LabelComponent,
    Input,
    Modal,
    snackbar,
  },
  data() {
    return {
      rules: {
        required_sucursal: (v) => !!v || "Sucursal es requerido",
        required_canal: (v) => !!v || "Canal es requerido",
        required: (value) => !!value || "El RUT es requerido.",
      },
      // profile_data: {},
      errorMsg: "",
      SnackBar: false,

      rut: "",
      disabled: true,
      items: [
        { id: 1, label: "Persona", icon: "bi bi-person" },
        { id: 2, label: "Autorizar", icon: "bi bi-shield-lock" },
      ],

      Nombre: {
        label: "",
        text: "",
        rules: [(v) => !!v || "Nombre es requerido"],
      },

      Apellido: {
        label: "",
        text: "",
        rules: [(v) => !!v || "Primer Apellido es requerido"],
      },

      SegundoApelido: {
        label: "",
        text: "",
        rules: [(v) => !!v || "Segundo Apellido es requerido"],
      },

      phone: {
        label: "",
        text: "",
        type: "number",
        rules: [(v) => !!v || "Número de Celular es requerido"],
      },

      Correo: {
        label: "",
        text: "",
        rules: [
          (v) => !!v || "Correo eletrónico es requerido",
          (v) => /.+@.+\..+/.test(v) || "El Correo debe ser válido",
        ],
      },

      Text_Canal: {
        label: "",
        answers: [],
        selected: "",

        id: [],
        rules: [(v) => !!v || "Canal es requerido"],
      },

      Text_Sucursal: {
        label: "",
        answers: [],
        selected: "",
        rules: [(v) => !!v || "Sucursal es requerido"],
      },
      Text_RUT: {
        label: "",
        text: "",
        type: Number,
        placeholder: "11.111.111-1",
        tipo: "rut",
        rules: [
          (value) => !!value || "El RUT es requerido.",
          (value) => value.length == 12 || "Rut inválido",
        ],
      },
    };
  },
  methods: {
    closeDialog() {
      this.$router.push({ name: "people" });
    },
    CreateUser() {
      var Form = {
        nombre: this.Nombre.text,
        primer_apellido: this.Apellido.text,
        segundo_apellido: this.SegundoApelido.text,
        celular: this.phone.text,
        correo_electronico: this.Correo.text,
        canal: this.Text_Canal.selected,
        sucursal: this.Text_Sucursal.selected,
        Rut: this.rut.replace(/[^A-Z0-9]/gi, ""),
        Convenio: this.$store.getters.url,
      };
      if (this.$refs.form.validate()) {
        this.$store.dispatch("PostNewUserPlatform", Form);
      }
    },
    closeSnack() {},
  },
  computed: {
    errorMsgUser() {
      return this.$store.getters.errorUser;
    },
    snackbarActivated() {
      if (this.$store.getters.errorUser !== "") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
@import "../../../assets/CSS/ServiceStyle.css";
@import "../../../assets/CSS/SelectField.css";
</style>
