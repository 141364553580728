<template>
  <div>
    <v-select
      v-if="!type"
      v-model="items.selected"
      :items="items.answers"
      item-text="label"
      item-value="value"
      :disabled="items.disabled || disabled"
      :placeholder="items.placeholder"
      atta
      outlined
      :rules="rules"
      :value="items.selected"
      @change="change"
      no-data-text="No hay información"
      :menu-props="{ offsetY: true }"
      :loading="loading"
      :hide-details="hideDetails"
      style="background: #fff"
      append-icon="mdi-chevron-down"
      dense
      ><template #item="{ item }">
        <span class="labelStyle"> {{ item.label }}</span>
      </template></v-select
    >
    <v-autocomplete
      v-else
      v-model="items.selected"
      :items="items.answers"
      item-text="label"
      item-value="value"
      :label="items.label"
      :disabled="items.disabled || disabled"
      :placeholder="items.placeholder"
      attach
      outlined
      dense
      :menu-props="{ offsetY: true }"
      :rules="rules"
      :value="items.selected"
      @change="change"
      no-data-text="No hay información"
      :loading="loading"
      :hide-details="hideDetails"
      ><template #item="{ item }">
        <span class="labelStyle"> {{ item.label }}</span>
      </template></v-autocomplete
    >
  </div>
</template>
<script>
export default {
  name: "drop",
  props: [
    "fields",
    "type",
    "color",
    "loading",
    "order",
    "rules",
    "disabled",
    "hideDetails",
  ],
  data() {
    return {
      field: this.items,
    };
  },
  methods: {
    change() {
      this.$emit("change", true);
    },
  },

  computed: {
    items() {
      return this.fields;
    },
    mergeWithNewData() {
      return [...new Set(this.items.answers)].sort();
    },
  },
  created() {
    this.$emit("fields", this.field);
    if (this.order === true) {
      if (this.items.answers) {
        this.items.answers.sort((a, b) => a.label.localeCompare(b.label));
      }
    }
  },
  mounted() {
    this.$emit("fields", this.field);
  },
  updated() {
    this.field = this.fields;
  },
};
</script>
